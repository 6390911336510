import React, { useEffect, useLayoutEffect, useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"

const loadModule = () =>
  new Promise(resolve => {
    const embedScript = document.createElement("script")
    embedScript.src = "https://cdn.storerocket.io/widget.js"
    embedScript.strategy = "off-main-thread"

    embedScript.onload = () => {
      resolve()
    }
    document.body.appendChild(embedScript)
  }).then(() => {
    window.StoreRocket?.init({
      selector: ".store-locator-widget",
      account: "6wpZZY2pAn",
      lang: "de",
    })
  })


const Stores = () => {  
  useEffect(() => {
    if (typeof window !== "undefined") {
      loadModule()
    }
  })

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          class: "html-class",
        }}
      >
      </Helmet>
      <Layout>
        <div className="relative z-0 pt-20 pb-32 container-wrapper min-h-48">
          <div className="lg:px-16 -mb-5 lg:mb-0 px-4 md:px-6 1.5xl:px-24">
            <h1 className="z-10 mb-6 text-4xl tracking-wide xxs:text-4xl lg:text-4xl text-primaryRed-500 xl:text-6xl mobile-h2">
              Finde einen Store in deiner Nähe
            </h1>
            <div className="min-h-56">
              <div
                data-storerocket-lang="de"
                className={`store-locator-widget`}
              />
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default Stores
